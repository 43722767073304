<template>
  <div>
    <v-card outlined>
      <div class="d-flex justify-md-space-around">
        <v-switch
          v-model="filterActive"
          @change="$emit('changeSlider', $event)"
        ></v-switch>
        <v-subheader
          class="caption pt-3"
          v-html="title"
        >
        </v-subheader>
      </div>
      <v-card-text class="pt-0 pb-0">
        <v-row>
          <v-col
            cols="6"
            class="mt-0 mb-0 pt-0 pb-0"
          >
            <!--note: для корректной работы в Firefox не слушаем событие change, т.к. при type="number" нажатие стрелочки генерит только input-->
            <v-text-field
              label="от"
              :value="model[0]"
              @input="handleChange($event, 0)"
              @blur="handleBlur"
              class="ma-0 pa-0"
              :rules="[rules.minValue, rules.maxValue]"
              type="number"
              :style="inputStyle"
              :disabled="!filterActive"
            ></v-text-field>
          </v-col>
          <v-col
            cols="6"
            class="mt-0 mb-0 pt-0 pb-0"
          >
            <v-text-field
              :empty-value="null"
              label="до"
              :value="model[1]"
              @input="handleChange($event, 1)"
              @blur="handleBlur"
              class="mt-0 pt-0"
              :rules="[rules.minValue, rules.maxValue]"
              type="number"
              :style="inputStyle"
              :disabled="!filterActive"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { cleanClone, deepEqual } from '@/components/utils/common'

export default {
  name: 'Slider',
  props: {
    value: {
      type: Array
    },
    range: {
      type: Array,
      required: true
    },
    inputStyle: {
      type: String,
      default: ''
    },
    title: String,
    sliderInput: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      filterActive: false,
      model: cleanClone(this.value),
      rules: {
        minValue: value => value >= this.range[0] || value === null || `Минимальное значение ${this.range[0]}`,
        maxValue: value => value <= this.range[1] || value === null || `Максимальное значение ${this.range[1]}`
      }
    }
  },
  watch: {
    value: {
      handler () {
        if (deepEqual(this.value, [null, null]) && this.filterActive) {
          this.filterActive = false
          this.model = cleanClone(this.value)
        }
      }
    },
    filterActive: {
      handler () {
        if (!this.filterActive) {
          this.model = [null, null]
          this.handleBlur()
        }
      }
    }
  },
  methods: {
    async handleChange (data, key = null) {
      if (data === '') {
        this.model[key] = null
      } else {
        this.model[key] = data *= 1
      }
    },
    async handleBlur () {
      // [-1, -1] соответсвует тех значению для обновления данных инпута
      let model = this.model.map(item => {
        if (item !== null) {
          if (item < this.range[0]) {
            item = this.range[0]
          } else if (item > this.range[1]) {
            item = this.range[1]
          }
        }
        return item
      })

      this.model = [-1, -1] // ставим некорректное значение для обновления данных в инпутах
      await this.$nextTick(() => {
        this.model = model
        return true
      })

      this.$emit('input', cleanClone(this.model))
    }
  },
  mounted () {
    if (!deepEqual(this.value, [null, null])) {
      this.filterActive = true
    }
  }
}
</script>

<style>
  .v-slider {
    display: none !important;
  }
</style>
