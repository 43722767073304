<template>
  <v-card outlined>
    <v-card-text>
      <v-radio-group v-model="districtClassification">
        <v-radio
          label="Народный"
          value="custom"
        ></v-radio>
        <v-radio
          label="Общегородской"
          value="official"
        ></v-radio>
      </v-radio-group>
      <v-treeview
        v-if="districtCustomItems && districtOfficialItems"
        :value="localSelected"
        @input="handleSelected($event)"
        :items="districtClassification === 'custom' ? treeCustom : treeOfficial"
        :key="districtClassification"
        item-key="localId"
        item-text="value"
        selectable
        selection-type="leaf"
        return-object
        dense
      ></v-treeview>
      <v-progress-circular
        v-else
        class="text-center"
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-card-text>
  </v-card>
</template>

<script>
import serviceDataComputeds from '@/components/mixins/serviceDataComputeds'
import formatters from '@/components/mixins/formatters'
import { deepEqual } from '@/components/utils/common'

export default {
  mixins: [serviceDataComputeds, formatters],
  name: 'DistrictSelect',
  props: {
    districtCustom: Object,
    districtOfficial: Object
  },
  data () {
    return {
      treeCustom: [],
      treeOfficial: [],
      districtClassification: null,
      localSelected: []
    }
  },
  watch: {
    districtClassification: {
      handler (newVal, oldVal) {
        if (oldVal && newVal !== oldVal) {
          this.$emit('update:districtOfficial', null)
          this.$emit('update:districtCustom', null)
        }
      }
    },
    districtCustom: {
      async handler () {
        if (this.districtCustom) {
          let processedItems = this.processSelected(this.districtCustom, this.districtCustomItems)
          if (!deepEqual(this.localSelected, processedItems)) {
            return this.$nextTick(() => {
              this.localSelected = processedItems
            })
          }
        } else if (!this.districtCustom && !this.districtOfficial) {
          return this.$nextTick(() => {
            this.localSelected = []
          })
        }
      },
      deep: true,
      immediate: true
    },
    districtOfficial: {
      async handler () {
        if (this.districtOfficial) {
          let processedItems = this.processSelected(this.districtOfficial, this.districtOfficialItems)
          if (!deepEqual(this.localSelected, processedItems)) {
            return this.$nextTick(() => {
              this.localSelected = processedItems
            })
          }
        } else if (!this.districtCustom && !this.districtOfficial) {
          return this.$nextTick(() => {
            this.localSelected = []
          })
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    handleSelected (data) {
      let districts = null
      if (data.length) {
        districts = {}
        data.forEach(item => {
          if (item.parent_id === null) {
            if (!districts.hasOwnProperty(item.id)) {
              districts[item.id] = []
            }
          } else {
            if (!districts.hasOwnProperty(item.parent_id)) {
              districts[item.parent_id] = []
            }
            districts[item.parent_id].push(item.id)
          }
        })
      }
      if (this.districtClassification === 'custom') {
        if (!deepEqual(this.districtCustom, districts)) {
          this.$emit('update:districtCustom', districts)
        }
      } else if (this.districtClassification === 'official') {
        if (!deepEqual(this.districtOfficial, districts)) {
          this.$emit('update:districtOfficial', districts)
        }
      }
    },
    processSelected (districtFilters, districtList) {
      let items = []
      for (let districtId in districtFilters) {
        if (districtFilters.hasOwnProperty(districtId)) {
          items.push(districtList.find(district => district.id === districtId))
          districtFilters[districtId].forEach(subdistrictId => {
            let item = {}
            if (subdistrictId === null) {
              item = {
                localId: null + '-' + districtId,
                id: null,
                value: 'Не указан',
                parent_id: districtId
              }
            } else {
              item = this.subdistrictItems.find(subdistrict => subdistrict.id === subdistrictId)
            }
            items.push(item)
          })
        }
      }
      return items
    },
    buildTree (items, subitems) {
      items.forEach((item, index) => {
        items[index].localId = items[index].id + '-' + items[index].parent_id
        items[index]['children'] = subitems
          .filter(child => child.parent_id === item.id)
          .map(child => {
            child.localId = child.id + '-' + child.parent_id
            return child
          })
        items[index]['children'].unshift({
          localId: null + '-' + item.id,
          id: null,
          value: 'Не указан',
          parent_id: item.id
        })
      })
      return items
    }
  },
  //note: сначала создаем дерево. localId - вычисляемый параметр. в данной ситуации id не уникален
  created () {
    let treeOfficial = this.districtOfficialItems
    if (treeOfficial) {
      this.treeOfficial = this.buildTree(treeOfficial, [])
    }
    let treeCustom = this.districtCustomItems
    if (treeCustom) {
      this.treeCustom = this.buildTree(treeCustom, this.subdistrictItems)
    }

    if (this.districtOfficial === undefined) {
      this.$emit('update:districtOfficial', null)
    }

    if (this.districtOfficial) {
      this.districtClassification = 'official'
    } else {
      this.districtClassification = 'custom'
    }
  }
}
</script>

<style scoped>

</style>
